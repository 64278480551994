import { ReactNode } from "react";
import { Outlet } from "react-router-dom";

import Padding from "../padding/Padding";

import HeaderLayout from "./components/HeaderLayout";

interface HeaderProps {
  children?: ReactNode;
}

const Header = ({ children }: HeaderProps) => {
  return (
    <>
      <HeaderLayout>{children}</HeaderLayout>
      <Padding>
        <Outlet />
      </Padding>
    </>
  );
};

export default Header;
