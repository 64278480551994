import { ReactNode } from "react";
import {
  Button,
  Drawer,
  DrawerContent,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";

import Padding from "../../padding/Padding";

import HeaderLink from "./HeaderLink";

interface HeaderLayoutProps {
  children?: ReactNode;
}

const HeaderLayout = ({ children }: HeaderLayoutProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const headerLinks = (isMobile: boolean): ReactNode => {
    return (
      <>
        <HeaderLink
          href="/#services"
          className={isMobile ? "!text-lg" : ""}
          onClick={() => {
            onClose();
          }}
        >
          Our Services
        </HeaderLink>
        <HeaderLink
          href="/#testimonials"
          className={isMobile ? "!text-lg" : ""}
          onClick={() => {
            onClose();
          }}
        >
          Testimonials
        </HeaderLink>
        <Button
          as="a"
          href="/contact-us"
          bg={"#1a202c"}
          marginTop={isMobile ? "0.5rem" : ""}
          size={isMobile ? "md" : "sm"}
          colorScheme="black"
          rounded={"3xl"}
        >
          CONTACT US
        </Button>
      </>
    );
  };

  const path = window.location.pathname;

  return (
    <>
      <Padding
        style={{ backgroundColor: path === "/" ? "#FFD1Dc" : "#FFFFFF" }}
        className={"py-6 font-poppins" + (path === "" ? "" : " bg-[#FFD1Dc]")}
      >
        <div className="flex">
          <a href="/" className="flex-none self-center font-quicksand text-3xl">
            <span className="font-semibold">alston</span>
            <span className="font-extralight">applications</span>
          </a>
          <div className="grow"></div>

          {/* Desktop header links */}
          <div
            data-testid={"desktop-header-menu"}
            className="flex-none self-center hidden md:block"
          >
            {headerLinks(false)}
          </div>

          {/* Mobile header links */}
          <div className="flex-none self-center md:hidden">
            <IconButton
              borderColor={"#00000030"}
              _hover={{
                backgroundColor: "#00000010",
                borderColor: "#00000050",
              }}
              size={"md"}
              variant={"outline"}
              aria-label="Open header menu"
              icon={<HamburgerIcon />}
              onClick={() => {
                onOpen();
              }}
            />
          </div>

          <Drawer
            placement="top"
            onClose={onClose}
            isOpen={isOpen}
            size={"full"}
            returnFocusOnClose={false}
          >
            <DrawerContent>
              <Padding>
                <div className="flex flex-col">
                  <IconButton
                    borderColor={"#00000030"}
                    _hover={{
                      backgroundColor: "#00000010",
                      borderColor: "#00000050",
                    }}
                    variant={"outline"}
                    aria-label="Close header menu"
                    icon={<CloseIcon />}
                    className={"mt-6 w-fit self-end"}
                    onClick={() => {
                      onClose();
                    }}
                  />
                  <div className="grow flex flex-col self-center w-full gap-4 py-12 max-w-[300px] mx-auto">
                    {headerLinks(true)}
                  </div>
                </div>
              </Padding>
            </DrawerContent>
          </Drawer>
        </div>
      </Padding>
      {children}
    </>
  );
};

export default HeaderLayout;
