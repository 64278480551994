import { ReactNode } from "react";

interface HeaderLinkProps {
  className?: string;
  children?: ReactNode;
  href: string;
  onClick: () => void;
}

const HeaderLink = ({
  className,
  children,
  href,
  onClick,
}: HeaderLinkProps) => {
  return (
    <a
      onClick={onClick}
      className={
        "scroll-smooth text-sm pr-2 md:pr-6 lg:pr-10 self-center " +
        (className ?? "")
      }
      href={href}
    >
      {children}
    </a>
  );
};

export default HeaderLink;
