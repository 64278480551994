import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";

import Header from "./components/header/Header";
// eslint-disable-next-line import/order
import LandingPage from "./pages/landingPage/LandingPage";

import "@aws-amplify/ui-react/styles.css";
import ContactUsPage from "./pages/contactUsPage/ContactUsPage";

function App() {
  const RedirectToDefaultDashboardPage = () => {
    useEffect(() => {
      window.location.href = "/";
    }, []);
    return null;
  };

  return (
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<Header />}>
            <Route path="" element={<LandingPage />} />
            <Route path="contact-us" element={<ContactUsPage />} />
            <Route path="*" element={<RedirectToDefaultDashboardPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
