import { ReactNode } from "react";

import { getPaddingValue } from "../padding/utils";
import useScreenSize from "../padding/hooks/useScreenSize";

interface CarouselProps {
  children?: ReactNode;
}

const Carousel = ({ children }: CarouselProps) => {
  const screenSize = useScreenSize();
  const marginLeft = getPaddingValue(screenSize.width);

  return (
    <>
      <div
        style={{ marginLeft: `${-marginLeft}px` }}
        className={`relative w-[100vw]`}
      >
        <div
          style={{ msOverflowStyle: "none", scrollbarWidth: "none" }}
          className="relative flex overflow-x-scroll snap-x pt-10 pb-2"
        >
          <div
            style={{ minWidth: `${marginLeft}px` }}
            className={`min-w-[252px] h-1`}
          ></div>
          {children}
          <div
            style={{ minWidth: `${marginLeft - 16}px` }}
            className={`min-w-[252px] h-1`}
          ></div>
        </div>
      </div>
    </>
  );
};

export default Carousel;
