export const getPaddingValue = (innerWidth: number) => {
  //XL screen
  if (innerWidth > 1280) {
    const contentWidth = 1024;
    return Math.round((innerWidth - contentWidth) / 2);
  }

  //LG screen
  if (innerWidth > 1024) {
    const contentWidth = 896;
    return Math.round((innerWidth - contentWidth) / 2);
  }

  //MD screen
  if (innerWidth > 768) {
    const contentWidth = 672;
    return Math.round((innerWidth - contentWidth) / 2);
  }

  //SM screen
  if (innerWidth > 640) {
    const contentWidth = innerWidth * 0.85;
    return Math.round((innerWidth - contentWidth) / 2);
  }

  const contentWidth = innerWidth * 0.9;
  return Math.round((innerWidth - contentWidth) / 2);
};
