import { Badge, Button } from "@chakra-ui/react";

interface ServiceCardProps {
  badgeCopy?: string;
  title: string;
  description: string;
}

const ServicesCard = ({ badgeCopy, title, description }: ServiceCardProps) => {
  return (
    <>
      <div className="flex flex-col relative border-t-8 border-[#512B58] rounded-lg p-6 mr-4 bg-white drop-shadow-md min-w-[300px]">
        <div className="min-h-[60px]">
          <h3 className="font-poppins font-semibold text-lg">{title}</h3>
          {badgeCopy && (
            <Badge
              style={{
                background: "linear-gradient(to right, #fd8cff,#ffa67c)",
              }}
            >
              {badgeCopy}
            </Badge>
          )}
        </div>
        <p className="grow mt-2 font-light">{description}</p>
        <Button
          className="flex-none"
          as={"a"}
          href="contact-us"
          colorScheme="pink"
          variant={"outline"}
          mt={4}
        >
          Find out more
        </Button>
      </div>
    </>
  );
};

export default ServicesCard;
