interface ContactUsEventBody {
  name: string;
  email: string;
  phoneNumber: string;
  message: string;
  recaptchaToken: string;
}

const openApi: string = process.env.REACT_APP_PLATFORM_API ?? "";
const ApiKey: string = process.env.REACT_APP_PLATFORM_API_KEY ?? "";

export const ContactUs = async (request: ContactUsEventBody) => {
  const response = await fetch(openApi + "/contact-us", {
    method: "POST",
    headers: {
      "x-api-key": ApiKey,
    },
    body: JSON.stringify(request),
  }).then((data) => {
    if (data.ok && data.status === 200) {
      return data.json();
    } else {
      throw "non 2xx response";
    }
  });

  return response;
};
