import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";

import { ReactComponent as SVG2 } from "../../../images/dot-pattern.svg";
import useContactForm from "../../contactUsPage/hooks/useContactForm";

const Hero = () => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [token, setToken] = useState<string>("");
  const [timeOfLastRecaptcha, setTimeOfLastRecaptcha] = useState(0);
  const [verifyForm, setVerifyForm] = useState(false);
  const { submitContactForm, isLoading, sent, error } = useContactForm();
  const toast = useToast();

  useEffect(() => {
    if (sent) {
      toast({
        title: "Message sent",
        description: "You will shortly receive a confirmation email.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setVerifyForm(false);
      setName("");
      setEmail("");
      setPhoneNumber("");
    }
    if (error) {
      toast({
        title: "Something went wrong",
        description: "Please try again, or come back later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [sent, error, toast]);

  useEffect(() => {
    if (timeOfLastRecaptcha < Date.now() - 30000) {
      setRefreshReCaptcha((r) => !r);
      setTimeOfLastRecaptcha(Date.now());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, email, phoneNumber]);

  const onVerify = useCallback(
    (newToken: string) => {
      setToken(newToken);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshReCaptcha],
  );

  return (
    <div className="relative">
      <div className="absolute left-[50%] top-0 bottom-0 w-[100vw] -z-10 -translate-x-2/4 bg-[linear-gradient(#FFD1Dc,#FFE5EC);] overflow-hidden">
        {/* <img
          alt=""
          src={SVG}
          className=" w-[400px] block absolute -bottom-[160px] -left-[150px]"
        /> */}
        <SVG2 className="fill-[#fef4f7] h-[300px] w-[300px] absolute -bottom-[150px] left-[20px] rotate-[45deg]" />
      </div>
      <div className="flex flex-col md:flex-row md:items-center w-full">
        <div className="grow max-w-[90%] md:max-w-[50%] align-middle mt-14 mb-10 md:my-36 lg:my-32">
          <h1 className="font-prosto xl:text-5xl text-4xl font-extrabold flex flex-col leading-tight">
            Bristol based software development company
          </h1>
          <p className="font-poppins mt-4 font-light">
            We are a software development company specialising in custom website
            development, API integration, and scalable cloud based software
            solutions. We builds secure, high-performance applications tailored
            to your business needs, from bespoke web design to cloud-based
            platforms and data solutions. We deliver technology that drives your
            growth.
          </p>
          {/* <Button
            marginTop={"1.5rem"}
            rightIcon={<ArrowDownCircle />}
            variant={"outline"}
            colorScheme="black"
            rounded={"3xl"}
            onClick={() => {
              const features = document.getElementById("services");
              if (features) {
                features.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            WHAT WE OFFER
          </Button> */}
        </div>
        <div className="grow"></div>
        <div className="bg-[#FFE5EC] p-12 rounded-xl shadow-xl mb-20 md:mb-0 relative w-full md:w-fit overflow-hidden md:overflow-visible">
          <SVG2 className="fill-[#FFE5EC] h-[120px] w-[120px] absolute -top-[50px] -right-[50px] -z-10" />
          <FormControl isInvalid={verifyForm && !name}>
            <label className="text-md font-bold text-[#533d4e]" htmlFor="name">
              Name
            </label>
            <Input
              borderColor={"#533d4e"}
              color={"#533d4e"}
              _placeholder={{ color: "#533d4e" }}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              variant="flushed"
              placeholder="John Smith"
              id="name"
            />
            <FormErrorMessage>A name is required</FormErrorMessage>
          </FormControl>
          {/* Email */}
          <FormControl isInvalid={verifyForm && !email} className="mt-6">
            <label className="text-md font-bold text-[#533d4e]" htmlFor="email">
              Email
            </label>
            <Input
              borderColor={"#533d4e"}
              color={"#533d4e"}
              _placeholder={{ color: "#533d4e" }}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              variant="flushed"
              placeholder="john.smith@gmail.com"
              id="email"
            />
            <FormErrorMessage>Email is required</FormErrorMessage>
          </FormControl>

          {/* Phone Number */}
          <FormControl isInvalid={verifyForm && !phoneNumber} className="mt-6">
            <label
              className="text-md font-bold text-[#533d4e]"
              htmlFor="phone-number"
            >
              Phone Number
            </label>
            <Input
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              borderColor={"#533d4e"}
              color={"#533d4e"}
              _placeholder={{ color: "#533d4e" }}
              variant="flushed"
              placeholder="07214589745"
              id="phone-number"
            />
            <FormErrorMessage>Phone number is required</FormErrorMessage>
          </FormControl>
          <Button
            w={"full"}
            isLoading={isLoading}
            marginTop={8}
            colorScheme="black"
            bg={"#1a202c"}
            rounded={"3xl"}
            onClick={() => {
              setVerifyForm(true);
              if (!name || !phoneNumber || !email) {
                return;
              }
              submitContactForm(
                name,
                email,
                phoneNumber,
                "Requested Call Back",
                token,
              );
            }}
          >
            Request call back
          </Button>
        </div>
      </div>
      <GoogleReCaptchaProvider reCaptchaKey="6Lcqot0qAAAAAAWDj1pkL2SRXvu85xvsPZrYHiw7">
        <GoogleReCaptcha
          action="RequestCallBack"
          onVerify={onVerify}
          refreshReCaptcha={refreshReCaptcha}
        />
      </GoogleReCaptchaProvider>
    </div>
  );
};

export default Hero;
