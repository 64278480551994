import ServicesCard from "../../../components/servicesCard/ServicesCard";
import Carousel from "../../../components/carousel/Carousel";

const FeaturesWeOffer = () => {
  const heading = (
    <>
      <p className="text-red-700 font-poppins font-bold">SERVICES</p>
      <h2 className="font-prosto text-4xl font-semibold">Services we offer</h2>
      <p className="font-poppins font-light mt-2">
        We offer a full range of cutting-edge software development services
        designed to help businesses innovate, scale, and stay ahead. From
        bespoke website development to robust API solutions, our experts ensure
        high-quality, scalable, and secure software tailored to your needs.
      </p>
    </>
  );

  return (
    <div id="services" className="relative">
      <div className="absolute left-[50%] top-0 bottom-0 w-[100vw] -z-10 -translate-x-2/4 overflow-hidden">
        {/* <img
          alt=""
          src={SVG}
          className="hidden w-[400px] md:block absolute -top-[100px] -left-[100px]"
        /> */}
        {/* <img
          alt=""
          src={SVG}
          className="hidden w-[400px] md:block absolute -bottom-[170px] -right-[100px]"
        /> */}
      </div>
      <div className="py-20">
        <>{heading}</>
        <Carousel>
          <ServicesCard
            badgeCopy="most popular"
            title="Website Development"
            description="Craft a unique, high-performance website tailored to your brand. We specialize in bespoke design, ensuring your site stands out with modern aesthetics and seamless user experience. Our websites are built to be scalable, secure, and optimized for performance, with advanced monitoring, A/B testing, and analytics integration to maximize conversions."
          />
          <ServicesCard
            title="API Development & Integration"
            description="Unlock the power of seamless digital connectivity with our custom API development services. Whether you need a RESTful API, GraphQL, or third-party integrations, we build secure, scalable, and well-documented APIs that ensure smooth communication between your applications and external platforms."
          />
          <ServicesCard
            title="Data Science Solutions"
            description="Scalable solutions for storing, ingesting, and managing massive datasets efficiently. We design cost-effective architectures, optimize data pipelines, and ensure seamless data flow to maximize performance while minimizing infrastructure expenses."
          />
          <ServicesCard
            title="Cloud & DevOps Solutions"
            description="Optimize your infrastructure with our Cloud and DevOps services. We specialize in AWS, offering CI/CD pipelines, serverless, and automated deployments to improve scalability and reliability."
          />
          <ServicesCard
            title="Custom Software Development"
            description="We design and build custom software solutions tailored to your business needs. Whether it’s an enterprise application, SaaS platform, or automation tool, we focus on scalability, performance, and security to ensure long-term success."
          />
        </Carousel>
      </div>
    </div>
  );
};

export default FeaturesWeOffer;
