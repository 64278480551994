import Carousel from "../../../components/carousel/Carousel";
import TestimonialCard from "../../../components/testimonialCard/TestimonialCard";

const Testimonials = () => {
  const heading = (
    <>
      <p className="text-red-700 font-poppins font-bold">TESTIMONIALS</p>
      <h2 className="font-prosto text-4xl font-semibold">
        What our clients have to say
      </h2>
      <p className="font-poppins font-light mt-2">
        Don’t just hear it from us - our Bristol based businesses and
        organisations also rave about us!
      </p>
    </>
  );

  return (
    <div id="testimonials" className="relative">
      <div className="bg-[#fefbfc] absolute left-[50%] top-0 bottom-0 w-[100vw] -z-10 -translate-x-2/4"></div>
      <div className="py-20">
        <>{heading}</>
        <Carousel>
          <TestimonialCard
            name="Hans-Peter Kiekuth"
            position="Chairman of the Bristol and District Triathlon Club"
            testimonial=""
          />
          <TestimonialCard
            name="Alan Ward"
            position="Founder of TriFwd coaching"
            testimonial="Mark was incredibly helpful in highlighting a few areas that I didn't know early on in developing my business. Some simple, once explained, SEO tips resulted in immediate tangible progress for me."
          />
        </Carousel>
      </div>
    </div>
  );
};

export default Testimonials;
