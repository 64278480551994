import { useState } from "react";

import { ContactUs } from "../../../api/openApi";

const useContactForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [sent, setSent] = useState(false);

  const submitContactForm = async (
    name: string,
    email: string,
    phoneNumber: string,
    message: string,
    recaptchaToken: string,
  ) => {
    setIsLoading(true);

    try {
      await ContactUs({
        name,
        email,
        phoneNumber,
        message,
        recaptchaToken,
      });
      setSent(true);
    } catch (e) {
      setError(e as string);
    }

    setIsLoading(false);
  };

  return { submitContactForm, isLoading, sent, error };
};

export default useContactForm;
