import Footer from "../../components/footer/Footer";

import Demo from "./components/Testimonials";
import FeaturesWeOffer from "./components/FeaturesWeOffer";
import Hero from "./components/Hero";

const LandingPage = () => {
  return (
    <>
      <Hero />
      <FeaturesWeOffer />
      <Demo />
      <Footer />
    </>
  );
};

export default LandingPage;
