import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { Send } from "react-feather";
import { useCallback, useEffect, useState } from "react";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";

import { ReactComponent as SVG } from "../../../images/dot-pattern.svg";
import useContactForm from "../hooks/useContactForm";

const ContactUsForm = () => {
  const [verifyForm, setVerifyForm] = useState(false);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const { submitContactForm, isLoading, sent, error } = useContactForm();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [token, setToken] = useState<string>("");
  const [timeOfLastRecaptcha, setTimeOfLastRecaptcha] = useState(0);
  const toast = useToast();

  useEffect(() => {
    if (sent) {
      toast({
        title: "Message sent",
        description: "You will shortly receive a confirmation email.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setVerifyForm(false);
      setName("");
      setEmail("");
      setPhoneNumber("");
      setMessage("");
    }
    if (error) {
      toast({
        title: "Something went wrong",
        description: "Please try again, or come back later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [sent, error, toast]);

  useEffect(() => {
    if (timeOfLastRecaptcha < Date.now() - 30000) {
      setRefreshReCaptcha((r) => !r);
      setTimeOfLastRecaptcha(Date.now());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, email, phoneNumber, message]);

  const onVerify = useCallback(
    (newToken: string) => {
      setToken(newToken);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshReCaptcha],
  );

  const heading = (
    <>
      <p className="text-red-700 font-poppins font-bold">EMAIL</p>
      <h2 className="font-prosto text-4xl font-semibold">Contact Us</h2>
      <p className="font-poppins font-light mt-2">
        Once submitted you&apos;ll receive a confirmation email that we&apos;ve
        got your message. We&apos;ll get back to you within one working day.
      </p>
      <div className="relative bg-[linear-gradient(#FFD1Dc,#FFE5EC);] rounded-xl p-16 my-24">
        <h3 className="text-[#533d4e] font-prosto text-3xl font-semibold mb-8">
          Contact Form
        </h3>
        <div className="flex flex-col md:flex-row gap-8">
          <div className="flex flex-col grow flex-1 font-poppins text-white">
            {/* Name */}
            <FormControl isInvalid={verifyForm && !name}>
              <label
                className="text-[#533d4e] text-sm font-semibold"
                htmlFor="name"
              >
                Name
              </label>
              <Input
                borderColor={"#533d4e"}
                color={"#533d4e"}
                _placeholder={{ color: "#533d4e" }}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                variant="flushed"
                placeholder="John Smith"
                id="name"
              />
              <FormErrorMessage>Name is required</FormErrorMessage>
            </FormControl>

            {/* Email */}
            <FormControl className="mt-6" isInvalid={verifyForm && !email}>
              <label
                className="text-[#533d4e] text-sm font-semibold"
                htmlFor="email"
              >
                Email
              </label>
              <Input
                borderColor={"#533d4e"}
                color={"#533d4e"}
                _placeholder={{ color: "#533d4e" }}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                variant="flushed"
                placeholder="john.smith@gmail.com"
                id="email"
              />
              <FormErrorMessage>Email is required</FormErrorMessage>
            </FormControl>

            {/* Phone Number */}
            <FormControl
              className="mt-6"
              isInvalid={verifyForm && !phoneNumber}
            >
              <label
                className="text-[#533d4e] text-sm font-semibold"
                htmlFor="phone-number"
              >
                Phone Number
              </label>
              <Input
                borderColor={"#533d4e"}
                color={"#533d4e"}
                _placeholder={{ color: "#533d4e" }}
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
                variant="flushed"
                placeholder="07214589745"
                id="phone-number"
              />
              <FormErrorMessage>Phone number is required</FormErrorMessage>
            </FormControl>
          </div>

          {/* Message */}
          <div className="grow text-white font-poppins flex-1">
            <FormControl
              className="flex flex-col grow h-full"
              isInvalid={verifyForm && !message}
            >
              <label
                className="text-[#533d4e] text-sm font-semibold"
                htmlFor="message"
              >
                Message
              </label>
              <Textarea
                borderColor={"#533d4e"}
                color={"#533d4e"}
                _placeholder={{ color: "#533d4e" }}
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                className="flex-1"
                variant="flushed"
                placeholder="Hi there ..."
                id="message"
              />
              <FormErrorMessage>A message is required</FormErrorMessage>
            </FormControl>
          </div>
        </div>
        <Button
          colorScheme="black"
          bg={"#1a202c"}
          isLoading={isLoading}
          loadingText="Send Message"
          rightIcon={<Send size={18} />}
          spinnerPlacement="end"
          marginTop={"12"}
          rounded={"3xl"}
          onClick={() => {
            setVerifyForm(true);
            if (!name || !phoneNumber || !email || !message) {
              return;
            }
            submitContactForm(name, email, phoneNumber, message, token);
          }}
        >
          Send Message
        </Button>
        <SVG className="fill-[#FFD1Dc] h-[120px] w-[120px] absolute -bottom-[50px] -right-[50px] -z-10" />
      </div>
      <GoogleReCaptchaProvider reCaptchaKey="6Lcqot0qAAAAAAWDj1pkL2SRXvu85xvsPZrYHiw7">
        <GoogleReCaptcha
          action="ContactUs"
          onVerify={onVerify}
          refreshReCaptcha={refreshReCaptcha}
        />
      </GoogleReCaptchaProvider>
    </>
  );

  return (
    <div id="services" className="relative">
      <div className="absolute left-[50%] top-0 bottom-0 w-[100vw] -z-10 -translate-x-2/4 overflow-hidden"></div>
      <div className="py-20">
        <>{heading}</>
      </div>
    </div>
  );
};

export default ContactUsForm;
