import Footer from "../../components/footer/Footer";

import ContactUsForm from "./components/ContactUsForm";

const ContactUsPage = () => {
  return (
    <>
      <ContactUsForm />
      <Footer />
    </>
  );
};

export default ContactUsPage;
