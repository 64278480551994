import { Avatar } from "@chakra-ui/react";

interface ServiceCardProps {
  name: string;
  position: string;
  testimonial: string;
}

const TestimonialCard = ({ name, position, testimonial }: ServiceCardProps) => {
  return (
    <>
      <div className="relative border-t-8 border-[#512B58] rounded-lg p-6 mr-4 bg-white drop-shadow-md min-w-[300px] flex-1">
        <div className="flex gap-4 items-center">
          <Avatar className="flex-none" />
          <div className="grow">
            <h3 className="font-poppins font-semibold text-md">{name}</h3>
            <p className="font-poppins font-light text-sm">{position}</p>
          </div>
        </div>

        <p className="mt-2 font-light">{testimonial}</p>
      </div>
    </>
  );
};

export default TestimonialCard;
